import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";


import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Featuress from "components/features/ThreeColWithSideImage.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";


const HighlightedText = tw.span`text-primary-500`


export default () => {
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  return (
    <AnimationRevealPage>
      <Hero />
      <Features
        heading={
          <>
            Amazing <HighlightedText>Services.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: "Active Users",
            description: "enjoy the active patronage of our active customers",
            url: "https://google.com"
          },
          {
            imageSrc: chefIconImageSrc,
            title: "Professional Chefs",
            description: "Our chefs are professional and verified",
            url: "https://timerse.com"
          },
          {
            imageSrc: celebrationIconImageSrc,
            title: "Amazing Recipes",
            description: "Our recipes are delicious and healthy",
            url: "https://reddit.com"
          }
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      {/* <Features 
        heading={<>Amazing <HighlightedText>Features</HighlightedText></>}
      /> */}
      <FeatureStats/>
      <Featuress
        heading={<>On Sexy Chef <HighlightedText> App, You Can</HighlightedText></>}
      />
      {/* <MainFeature
        heading={<>Cloud built by and for <HighlightedText>Professionals</HighlightedText></>}
      /> */}
      {/* <Testimonials 
        heading={<>Our Clients <HighlightedText>Love Us</HighlightedText></>}
      /> */}
       <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      />


      {/* <Pricing 
        heading={<>Flexible <HighlightedText>Plans</HighlightedText></>}
      /> */}
      {/* <FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
      /> */}
      {/* <Blog
        subheading="Blog"
        heading={<>We love <HighlightedText>Writing</HighlightedText></>}
      /> */}
      {/* <GetStarted/> */}
      <DownloadApp text={<>People around you are ordering chefs using the <HighlightedTextInverse>Sexy Chef App.</HighlightedTextInverse></>} />
      <Footer />
    </AnimationRevealPage>
  );
}
